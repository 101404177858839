import React from "react";
import {GlobalStyle, MainContainer} from "../style";
import Head from "../components/common/head";
import NavBar from "../components/common/navBar";
import FooterBar from "../components/common/footer";
import HomeBlock from "../components/homepage/home";
import Advisors from "../components/homepage/advisors";
import CPOEMTeam from "../components/homepage/cpoemTeam";
import PastEvents from "../components/homepage/pastEvents";
// import OurPartner from "../components/homepage/ourPartner";
import Testimonials from "../components/homepage/testimonials";
import PatientSupport from "../components/homepage/patientSupport";
import CPOEMNewsLetter from "../components/homepage/cpoemNewsletter";
import InteractiveEvents from "../components/homepage/interactiveEvents";
import KnowledgeMakesDifference from "../components/homepage/knowledgeMakesDifference";

const Homepage = () => {
  return (
    <MainContainer>
      <GlobalStyle/>
      <Head/>
      <NavBar/>
      <HomeBlock/>
      <KnowledgeMakesDifference/>
      <InteractiveEvents
        putSpace={false}
        showViewMore={true}
      />
      <Testimonials/>
      <PastEvents
        putSpace={false}
        showViewMore={true}
      />
      <PatientSupport showQuestions={true}/>
      <CPOEMTeam/>
      <Advisors/>
      {/* <OurPartner/> */}
      <section className="section-spacing"/>
      <CPOEMNewsLetter/>
      <FooterBar/>
    </MainContainer>
  );
};

export default Homepage;