import React, {Fragment} from "react";
import {Link} from "gatsby";
import {CenteredContainer, TeamBlock} from "../../style";
import cpoemTeamBadge from "../../assets/images/cpoem-team-badge.svg";
import teamOne from "../../assets/images/team-1.png";
import teamTwo from "../../assets/images/team-2.jpg";

const CPOEMTeam = () => {
  const teamData = [{
    name: "Dr. (Prof) Vishwanath S",
    qualification: `MD (Internal Medicine) DM (Medical
      Oncology) Fellow - MD Anderson Cancer
      Center, Houston, USA Consultant
      Oncologist Apollo Hospitals, India`,
    photo: teamTwo,
    link: "/the-team"
  }, {
    name: "Dr. Santosh K Devdas",
    qualification: `MD (Medicine) DM (Medical Oncology) 
      Associate Professor and Chief stem cell
      transplant physician Ramaiah Medical College
      and Hospitals, India`,
    photo: teamOne,
    link: "/the-team"
  }]

  return (
    <Fragment>
      <CenteredContainer className="cpoem-team section-spacing">
        <img src={cpoemTeamBadge} className="logo" alt="logo"/>
        <h2 className="heading-text">
          <span>Committed to building a knowledge pool</span>
          that everyone can access
        </h2>
        <TeamBlock>
          {
            teamData.map(el => {
              const {name, qualification, photo, link} = el;
              return (
                <div key={name + '-' + qualification} className="flex-container team-blocks vertical-end">
                  <div className="flexbox">
                    <img src={photo} className="team" alt="team"/>
                  </div>
                  <div className="flexbox team-details">
                    <h3 className="name">{name}</h3>
                    <p className="qualification">{qualification}</p>
                    <Link to={link} className="btn blue" target="_self">Know more</Link>
                  </div>
                </div>
              );
            })
          }
        </TeamBlock>
      </CenteredContainer>
    </Fragment>
  );
};

export default CPOEMTeam;