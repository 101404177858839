import testimonialOne from "../assets/images/testimonial-0.jpg";
import testimonialTwo from "../assets/images/testimonial-1.jpg";

export const testimonials = [{
  text: `CPOEM is a very novel initiative. It's creative as in the name itself. 
  This initiative has the potential to transform the 'cancer care' across the 
  globe due to its stated mission of collaborating with world leaders in 
  oncology practice, it's educative purpose, awareness programmes and bringing 
  state of the art and cutting edge evidence based oncology practice knowlege to 
  the grass root level practitioners and then to the actual beneficiaries 
  'The Patient' I wish best of luck to the entire team for fulfilling this dream`,
  doctor: "Dr. Govindarajan MJ",
  hospital: "Apollo Hospitals, Bangalore",
  pic: testimonialOne
}, {
  text: `As a medical oncology student, CPOEM has been of immense help to understand the subject. 
  Their seminars are not only conducted by THE eminent speaks from around the world, 
  they give you a chance to interact with them. I especially look forward to their 
  pre-seminar quiz, which helps me understand the seminar even better.`,
  doctor: "Fellow - Medical Oncology",
  hospital: "3rd Year DM",
  pic: testimonialTwo
}];