import React, {useEffect, useRef} from "react";
import Swiper from "swiper";
import {TestimonialContainer, variables} from "../../style";
import {testimonials} from "../../configs/testimonials.config";

const Testimonials = () => {
  const swiper = useRef();
  useEffect(() => {
    const {breakpoint, mobilePortrait} = variables;
    const {innerWidth} = window;
    new Swiper('#testimonial-swiper', {
      initialSlide: 0,
      slidesPerView: (
        innerWidth <= mobilePortrait ? 1 : (
          innerWidth <= breakpoint - 1 ? 2 : 3
        )
      ),
      spaceBetween: 10,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });

    setTimeout(() => {
      if(swiper.current){
        const {height} = swiper.current.getBoundingClientRect();
        swiper.current.style.height = `${height}px`;
      }
    }, 500);
  }, []);

  return (
    <TestimonialContainer className="testimonial-container">
      <h2 className="section-heading">Testimonials</h2>
      <div className="swiper-container" ref={swiper} id="testimonial-swiper">
        <div className="swiper-wrapper">
          {
            testimonials.map(el => {
              const {text, doctor, hospital, pic} = el;
              return (
                <div className="swiper-slide testimonial-box text-center" key={text}>
                  <div className="text-container relative">
                    <div className="testimonial">{text}</div>
                    <div className="doctor-hospital">
                      {`${doctor}, ${hospital}`}
                    </div>
                  </div>
                  <div className="pic-container">
                    <img src={pic} alt="doctor pic" className="doctor"/>
                  </div>
                </div>
              )
            })
          }
        </div>
        <div className="swiper-button-prev"></div>
        <div className="swiper-button-next"></div>
      </div>
    </TestimonialContainer>
  )
};

export default Testimonials;