import React from "react";

const Svg = (props) => {
  const {fill} = props;

  return (
    <svg
      width="92"
      height="187"
      viewBox="0 0 92 187"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.0665 25.939L21.4178 0.0322266H70.7925L59.9794 25.939H32.0665Z"
        fill={fill}
      />
      <path
        d="M50.0458 68.2051L78.1989 0.754883L91.7513 33.7258L63.5981 101.176L50.0458 68.2051Z"
        fill={fill}
      />
      <path
        d="M28.1584 186.511L42.2706 152.701L28.7185 119.729L0.844055 186.511H28.1584Z"
        fill={fill}
      />
      <path
        d="M91.3683 186.511L14.4796 0.928223L0.748718 33.825L64.1292 186.511H91.3683Z"
        fill={fill}
      />
    </svg>
  )
};

export default Svg;