import React, {Fragment} from "react";
import {graphql, useStaticQuery} from "gatsby";
import {FullWidthContainer, HomeBlockBox, colors} from "../../style";

import moment from "moment";

import Svg from "./svg";
import book from "../../assets/images/book.svg";
import doctor from "../../assets/images/doctor.svg";
import knowledge from "../../assets/images/knowledge.svg";
import conversation from "../../assets/images/conversation.svg";

const HomeBlock = () => {
  const {allWebinar: {edges = []}} = useStaticQuery(graphql`
    query OnlyOneUpcomingWebinarQuery {
      allWebinar(
        sort: {order: ASC, fields: start_time}
      ) {
        edges {
          node {
            topic
            start_time
            registration_url
          }
        }
      }
    }
  `);

  const {node: upcomingWebinar = {}} = edges.find(el => {
    const {node: {start_time}} = el;
    return (new Date(start_time).getTime() > new Date().getTime());
  }) || {};
  const {topic, start_time, registration_url} = upcomingWebinar;

  const fourBlock = [{
    background: colors.blue,
    symbol: colors.yellow,
    icon: conversation
  }, {
    background: colors.green,
    symbol: colors.pink,
    icon: doctor
  }, {
    background: colors.yellow,
    symbol: colors.blue,
    icon: book
  }, {
    background: colors.pink,
    symbol: colors.green,
    icon: knowledge
  }];

  return (
    <FullWidthContainer className="home">
      <div className="flex-container vertical-middle black-box">
        <div className="event-details flexbox text-center">
          {!topic && <span className="bold no-margin">Stay tuned for upcoming webinars</span>}
          {topic &&
            <Fragment>
              Upcoming Webinar:
              <span className="bold">{topic}</span>
              <span className="small">{moment(start_time).format("dddd D MMM YYYY")}</span>
            </Fragment>
          }
        </div>
        {topic &&
          <div className="register-button">
            <a
              href={registration_url}
              target="_blank"
              rel="noreferrer"
              className="btn green no-padding text-uppercase"
            >Register Now</a>
          </div>
        }
      </div>
      <div className="link-block grid-container">
        {
          fourBlock.map((el, index) => (
            <HomeBlockBox
              key={el.symbol + el.background + index}
              background={el.background}
            >
              <div className="content flex-container vertical-middle justify-center">
                <Svg fill={el.symbol}/>
                <img src={el.icon} className="icon" alt="icon"/>
              </div>
            </HomeBlockBox>
          ))
        }
      </div>
    </FullWidthContainer>
  );
};

export default HomeBlock;