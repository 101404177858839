
import advisor1 from "../assets/images/advisor-1.png";
import advisor2 from "../assets/images/advisor-2.png";
import advisor3 from "../assets/images/advisor-3.png";
import advisor4 from "../assets/images/advisor-4.png";
import advisor5 from "../assets/images/advisor-5.png";
import advisor6 from "../assets/images/advisor-6.png";
import advisor7 from "../assets/images/advisor-7.png";
// import advisor8 from "../assets/images/advisor-8.png";

import teamOne from "../assets/images/team-1.png";
import teamTwo from "../assets/images/team-2.jpg";

export const teamList = [{
  pic: teamTwo,
  name: "Dr. (Prof) Vishwanath S",
  qualification: `MD (Internal Medicine) DM (Medical Oncology)
  Fellow - MD Anderson Cancer Center, Houston, USA 
  Consultant Oncologist Apollo Hospitals, India`,
  desc: `Dr. Vishwanath S is a highly accomplished medical oncologist with a 
  fellowship from the University of Texas, MD Anderson Cancer Center in Houston, TX, USA. 
  He has an outstanding academic record with gold medals in DM, Medical Oncology 
  (Kidwai Memorial Institute of Oncology) and MD, Internal Medicine (Kasturba Medical College). 
  He specializes in Lymphoma/ Multiple Myeloma, Leukemias and solid cancers 
  (Breast, Gynaecological, Lung, Head and Neck, GI and Sarcomas). 
  He has published widely in journals with high impact factor and has over 
  40 international publications. He was won several prestigious awards including an 
  Abstract Achievement Award from the American Society of Hematology (ASH). 
  Based on his achievements, the Apollo Hospitals Educational and Research Foundation 
  (AHERF) bestowed on him the title of Professor in the Department of Medical Oncology in 2018.`,
  expertise: [
    "Lymphoma",
    `Multiple myeloma`,
    `Head and neck cancer`,
    `Solid cancers
    (Breast, Gynaecological, Lung, Head
    and Neck, GI and Sarcomas).`
  ],
  researchInterests: [],
  recognition: [
    `Conferred Professorship by Apollo Hospitals Education and Research Foundation`,
    `ASH Abstract Achievement Award`,
    `Gold Medal, Best Outgoing Medical Oncology Fellow, Kidwai Memorial Institute of Oncology, Rajiv Gandhi 
    University of Health Sciences`
  ]
}, {
  pic: teamOne,
  name: "Dr. Santosh K Devdas",
  qualification: `MD (Medicine) DM (Medical Oncology)
  Associate Professor and Chief stem cell transplant physician
  Ramaiah Medical College and Hospitals, India`,
  desc: `Dr Santhosh K Devadas is an  eminent  Medical oncologist and Bone marrow 
  transplant physician at Ramaiah Medical College and Hospitals. He serves as Associate 
  professor of Medical Oncology and Chief  of Bone marrow transplant at the institution. 
  He focuses on Hemato oncology,Breast Oncology and Gynaec Oncology. He has published 
  over 20 peer reviewed publications and has been a recipient of many grants and awards.
  His research interests include Implementation research and qualitative research. 
  He leads many phase III and investigator initiated clinical trials. 
  He is actively involved in training fellows in medical oncology and serves as 
  a guide and examiner for the fellowship program.`,
  expertise: ["Bone marrow transplant"],
  researchInterests: [],
  recognition: [
    `Member of the National Academy Medical Sciences, New Delhi`,
    `Awarded ACORD fellowship, Australian Medical Oncology group`,
    `Awarded the ESMO Travelling fellowship in September 2018- Advanced Course on Clinical`,
    `Questions in Prostate Cancer - Singapore`
  ]
}];

export const advisorList = [{
  pic: advisor1,
  name: "Fredrick Hagemeister",
  qualification: "UG (Chemistry), MD",
  desc: `Dr. Fredrick B. Hagemeister is a senior professor in the 
  department of lymphoma and myeloma at the University 
  of Texas, MD Anderson Cancer Center. He received MD from 
  University of Texas Southwestern Medical School-Dallas. 
  Followed by residency in medicine at Charity 
  Hospital-Louisiana. He started his Fellowship in 
  Cancer Medicine at MD Anderson, and joined the Department of 
  Hematology in 1979 and continued his clinical work in the 
  Department of Lymphoma/Myeloma as he carried out 
  multiple clinical trials of management of patients with all 
  types of lymphomas. He has authored or co-authored over 
  200 manuscripts, organized local and international 
  meetings and given presentations on cancer therapy 
  conferences. In 2016 he was awarded the Melvin Samuels 
  Award in Clinical Care from the Division of Cancer Medicine.`
}, {
  pic: advisor2,
  name: "Rashmi Kanagal Shamanna",
  qualification: "MD",
  desc: `Dr. Rashmi Kanagal Shamanna is a tenured 
  hematopathologist, molecular genetic pathologist and the 
  director of the Clinical Microarray Facility in the 
  CLIA-certified Molecular Diagnostic Laboratory. Her primary 
  research interest is the application of novel molecular 
  techniques to understand the pathogenesis of 
  hematological malignancies and guide therapy, with over 
  125 highly cited peer-reviewed publications and multiple 
  grants in her field. She is an elected member of multiple 
  national organizations focused on appropriate utilization of 
  genomic assays for patient care and editorial board 
  member for Cancer Genetics and an associate editor for 
  Cancer Medicine journals.`
}, {
  pic: advisor3,
  name: "Maryam Nemati Shafaee",
  qualification: "MD, MPH",
  desc: `Dr. Maryam Nemati is an assistant professor at the Baylor 
  College of Medicine, and a breast oncologist at Dan L 
  Duncan Comprehensive Cancer Center. She is a graduate of 
  Weill Cornell Medicine and New York Presbyterian Hospital 
  where she completed her internal medicine residency. At 
  MD Anderson Cancer Center, Dr. Nemati completed a 
  three-year fellowship in Hematology Oncology, and joined 
  Baylor College of Medicine for a one year fellowship in 
  translational breast cancer research before joining as 
  faculty in 2017. Dr. Nemati subsequently received a master 
  of public health at Harvard T.H. Chan school of public 
  health in 2019. Her research interests include biomarker 
  driven clinical trials in breast cancer therapy, and breast 
  cancer epidemiology and outcomes research. She is also 
  the associate director of the Patient Safety Course at 
  Baylor College of Medicine.`
}, {
  pic: advisor4,
  name: "Linu Abraham Jacob",
  qualification: "DM",
  desc: `Dr. Linu Abraham Jacob is a senior Medical Oncologist with 
  over 17 years of experience in the field of cancer care. He is 
  a Fellow of the American Cancer Society University (ACSU) 
  and the Union International for Cancer Control (UICC). He is 
  currently Professor and Head of Medical Oncology at Kidwai 
  Memorial Institute of Oncology, Bangalore, and a teacher 
  and guide for the DM Medical Oncology program at Kidwai 
  Memorial Institute of Oncology and a PhD guide under the 
  Rajiv Gandhi University of Health sciences (RGUHS), 
  Bangalore.`
}, {
  pic: advisor5,
  name: "Manjunath Ramarao",
  qualification: "Ph.D",
  desc: `Dr. Manjunath Ramarao is a senior vice president, 
  Research & Development at Ribometrix based in Durham, North Carolina. 
  He leads a large team of scientists involved in drug discovery research 
  efforts spanning diverse arrears including in vitro biology, 
  mechanistic biochemistry, genomics, protein sciences, biophysics, 
  crystallography, electrophysiology, computational biology, 
  compound management and automation. He joined BMS (Bristol-Myers Squibb) 
  in 2009 after an industrious career at Wyeth, Cambridge, MA, USA, 
  which spanned over eight years where he initiated and successfully 
  led several drug discovery programs. Prior to that he worked as an 
  Instructor in Neurobiology at Harvard Medical School, Boston, MA, USA. 
  He holds a Ph.D. and MS in physiology from the University of Illinois 
  at Urbana-Champaign, USA and M.Sc.(Biochemistry) and B.Sc. from 
  Bangalore University, India.`
}, {
  pic: advisor6,
  name: "Shaji Kumar",
  qualification: "M.D.",
  desc: `Dr. Shaji Kumar is a consultant in the Division of Hematology and a 
  professor of medicine with Mayo Clinic, Rochester, Minnesota. 
  He completed his Pre-Medical from Union Christisn College, MBBS, 
  Internship & Clinical Residency from All-India Institute of Medical sciences, 
  Senior Resident-Medical Oncology from Institute Rotary Cancer Hospital. 
  He did his Fellowship in Hematology Oncology from Mayo Graduate School of Medicine, 
  Mayo Clinic College of Medicine and he was Research Associate (Multiple Myeloma) 
  at DFCI/Harvard Cancer Center.Presently he is also a member of Institutional Review Board, 
  Mayo Clinic, Editorial advisory board, The Lancet Haematology, Associate editor, 
  American Journal of Hematology, from 2011, Board member, European Journal of Clinical 
  and Medical Oncology, from 2011, & Leukemia journal, from 2010.`
}, {
  pic: advisor7,
  name: "Sharlene Gill",
  qualification: "B.Sc (Pharm), MD, MPH, MBA, FACP, FRCP(C) BC Cancer",
  desc: `Dr. Gill is a Professor of Medicine at the University of British Columbia and a 
  medical oncologist specializing in gastrointestinal (GI) malignancies at BC Cancer - Vancouver. 
  She received a Bachelor's of Science in Pharmacy and an MD from the University of 
  British Columbia in 1996 followed by residencies in Internal Medicine and Medical Oncology. 
  Dr. Gill subsequently completed a fellowship in Gl Oncology at the Mayo Clinic (Rochester, MN) 
  and a Master's of Public Health from the Harvard School of Public Health. 
  In 2017, she completed an MBA from the Kenan-Flagler School of Business at the University 
  of North Carolina.  She is actively engaged in education and research, with over 100 peer-reviewed 
  publications and book chapters to her credit. Dr. Gill currently serves as the Chair of the 
  Canadian Clinical Trials Group (CCTG) GI Disease Site Committee, and is President-Elect for 
  the Canadian Association of Medical Oncologists.`
}];

// {
//   pic: advisor8,
//   name: "Dr Ramaswamy Govindan",
//   qualification: "MD",
//   desc: `Dr Ramaswamy Govindan is a senior Professor of Medicine, Division of Oncology, 
//   and Director, Section of Medical Oncology, Washington University School of Medicine. 
//   He completed his initial medical training from University of Madras India  followed by 
//   Residency and Fellowship at Washington University, St. Louis. His Clinical expertise 
//   includes Esophageal, thoracic and lung cancer, and research interests include developing 
//   clinical trials for patients with non-small cell lung cancer.`
// }
