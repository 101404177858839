import React, {Fragment} from "react";
import {Link} from "gatsby";
import {colors, CenteredContainer, FullWidthContainer, KnowledgeBox} from "../../style";

const KnowledgeMakesDifference = () => {
  const knowledgeBlocks = [{
    background: colors.purple,
    text: "Interactive, knowledge-first webinars with world-renowned oncologists",
    link: "/events/"
  }, {
    background: colors.violet,
    text: "Resource portal for practicing clinicians",
    link: "/education/"
  }, {
    background: colors.skyblue,
    text: "Credible and authentic information about cancer and cancer care",
    link: "/patient-support/"
  }, {
    background: colors.blue,
    text: "Unified platform for medical, radiation, surgical oncology pathology and radiology professionals",
    link: "/education/"
  }, {}, {
    background: colors.pink,
    text: "Knowledge portal for DM and DNB trainees in Oncology",
    link: "/education/"
  }];

  return (
    <Fragment>
      <CenteredContainer>
        <h2 className="heading-text">
          <span>Knowledge</span>
          makes all the difference
        </h2>
        <div className="small-text">
          Online education, upskilling and community platform
          for oncology and cancer care
        </div>
      </CenteredContainer>
      <FullWidthContainer className="knowledge">
        <div className="grid-container">
          {
            knowledgeBlocks.map((el, index) => {
              const {text, link, background="transparent"} = el;
              return (
                <KnowledgeBox
                  className="knowledge-blocks flex-container column"
                  background={background}
                  key={text + index}
                >
                  {(text || link) && 
                    <div className="content">
                      {text && <h3>{text}</h3>}
                      {link &&
                        <Link
                          to={link}
                          className="btn black no-padding text-left"
                          target="_self"
                        >Know more</Link>
                      }
                    </div>
                  }
                </KnowledgeBox>
              )
            })
          }
        </div>
      </FullWidthContainer>
    </Fragment>
  );
};

export default KnowledgeMakesDifference;