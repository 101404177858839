import React from "react";
import {AdvisorContainer} from "../../style";

import {advisorList} from "../../configs/team.config";

const Advisors = (props) => {
  const {heading} = props;

  return (
    <AdvisorContainer className="advisor-container">
      <h2 className="section-heading">{heading || "Advisors"}</h2>
      <div className="advisors grid-container">
        {
          advisorList.map((el, index) => {
            const {name, pic} = el;
            return (
              <div key={name + index} className="advisor text-center">
                <img src={pic} alt="advisor"/>
                <div className="name">{name}</div>
              </div>
            )
          })
        }
      </div>
    </AdvisorContainer>
  )
};

export default Advisors;